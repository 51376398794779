export const REDUX_FORM_OPTIONS = {
  enableReinitialize: true,
  destroyOnUnmount: false
}

export const APP_EDITION_PROVEN = 'proven'
export const APP_EDITION_SEPHORA = 'sephora'
export const APP_EDITION = process.env.APP_EDITION || APP_EDITION_PROVEN
export const SHOPIFY_APP = process.env.SHOPIFY_APP !== 'false'

// eslint-disable-next-line no-console
console.log('process.env.APP_EDITION', process.env.APP_EDITION)
// eslint-disable-next-line no-console
console.log('APP_EDITION', APP_EDITION)

// export const BUILDER_API_KEY = process.env.BUILDER_ENV_API_KEY || '41522f1b6cf543838120df677d7a6868'
export const BUILDER_API_KEY = process.env.BUILDER_ENV_API_KEY || '9e1c9500f106432dbfa7f5743e4868e1'

export const FORCE_SEARCH_PAGE_ON_STRAPI = process.env.FORCE_SEARCH_PAGE_ON_STRAPI
  ? process.env.FORCE_SEARCH_PAGE_ON_STRAPI === 'true'
  : false

export const BUILDER_RESOURCE_PAGE_BUILDER = 'page-builder'
export const BUILDER_RESOURCE_PAGE_STRAPI = 'page-strapi'

export const CRM_REDIRECT_PARAMS_MAPPING_VALUES = {
  redirectToCleanserPDP: '/personalized-face-cleanser/',
  redirectToMoisturizerPDP: '/personalized-day-moisturizer/',
  redirectToNightCreamPDP: '/personalized-night-cream/',
  redirectToEyeDuoPDP: '/personalized-eye-cream-duo/',
  redirectToDayEyeCreamPDP: '/personalized-day-eye-cream/',
  redirectToNightEyeCreamPDP: '/personalized-night-eye-cream/',
  redirectToSerumPDP: '/account/shop/serum/',
  redirectToShopPage: '/account/shop/',
  redirectToAccountPage: '/account/',
  redirectTo422: '/account/shop/onetime/hydration-boost-day-moisturizer/',
  redirectToPaymentUpdatePage: '/account/settings/info/payment',
  redirectToSpotTreatmentPage: '/spot-treatment-serum/'
}

export const PUBLIC_STOREFRONT_API_TOKEN =
  process.env.PUBLIC_STOREFRONT_API_TOKEN || 'c540df1951574c3693b12b8b2115ff39'
export const PUBLIC_STORE_DOMAIN =
  process.env.PUBLIC_STORE_DOMAIN || 'https://https-thirstcollective-staging1.myshopify.com'
export const STORE_ID = process.env.STORE_ID || '72332640553'
