// eslint-disable-next-line no-undef
const baseUrl = process.env.API_HOST || 'https://api-staging-shopify.tryproven.com'
// const baseUrl = process.env.API_HOST || 'http://0.0.0.0:3006'

export const strapiCmsUrl = 'https://cms.provenskincare.com/'
//export const strapiCmsUrl = "http://localhost:1337/";

export const apiUrl = `${baseUrl}/api/`

export const provenPayUrl =
  process.env.PROVEN_PAY_URL || 'https://pay-staging-shopify-dev.tryproven.com/'
// export const provenPayUrl = process.env.PROVEN_PAY_URL || 'http://0.0.0.0:3010/';

// eslint-disable-next-line no-undef
export const baseProtocolHost = process.env.BASE_URL || 'https://www.provenskincare.com'
// eslint-disable-next-line no-undef
export const baseCdnHost = process.env.BASE_CDN_HOST || 'provenskincare.com'

export const userUrl = `user`
export const shippingAddressUrl = `customer/shipping-address`
export const checkoutUrl = `/purchase/checkout`
export const subscribeAndSaveUrl = `/subscribe-and-save`
export const unsubscribeUrl = `purchase/cancel`
export const purchaseUrl = `purchase`
export const previewUrl = `purchase/preview`
export const resetPasswordUrl = `user/reset-password`
export const changePasswordUrl = `user/change-password`
export const sendReferralUrl = `user/send-referrals`
export const referralDashboardUrl = `user/referral-dashboard`
export const verifyAddressUrl = `purchase/address-validate`
export const orderHistoryUrl = (page, status, isSubscription) =>
  `orders/search?page=${page}${
    status ? (Array.isArray(status) ? status : [status]).map(s => `&status=${s}`).join('') : ''
  }${isSubscription ? `&isSubscription=true` : ''}`
export const orderDetailsUrl = id => `orders?orderId=${id}`
export const searchProductsUrl = query => `search/products?query=${query}`
export const campaignCaptureQuizUrl = `campaign-capture-quiz`
export const userQuizUrl = `user/quiz`
export const productsUrl = `products/main`
export const productDiscountsUrl = `discounts/automatic`
export const shopSubscriptionUrl = `shop-subscription`
export const productsSubscribeUrl = `products/subscribe`
export const presaleUserUrl = `user/presale`
export const hubspotUrl = `hubspot/contact`
export const checkUserExistsUrl = `check-user`
export const contactUsUrl = `contact-us`
export const contactUsUrlV2 = `contact-us-v2`
export const userPreferenceUrl = `user-preference`
export const userPaymentInfo = 'customer/payment-info'
export const updateUserPaymentInfo = 'customer/update-payment-info'
export const feedbackUrl = `feedback`
export const redeemGiftCardInFullUrl = `gift-card/redeem-in-full`
export const closeOrderUrl = 'customer/order/close'
export const conversationsTokenUrl = `conversations/token`
export const invoiceDetailsUrl = 'customer/invoice/details'
export const quizResultsUrl = 'quiz/results'
export const serumResultsUrl = 'quiz/results/serum'
export const eyeQuizUrl = `quiz-eye/concern`
export const storeFieldsUserUrl = `user/store-fields`
export const gorgiasContactUsURL = `${apiUrl}contact-us-v2`
export const yotpoWriteReviewURL = `${apiUrl}yotpo/write-review`
export const pdpUrlsMain = [
  '/',
  '/personalized-subscription',
  '/personalized-face-cleanser',
  '/personalized-day-moisturizer',
  '/personalized-night-cream',
  '/personalized-eye-cream-duo',
  '/personalized-day-eye-cream',
  '/personalized-night-eye-cream',
  '/personalized-serum'
]
export const pdpUrlsRud = [
  '/account/shop/skin',
  '/account/shop/skin/proven-cleanser',
  '/account/shop/skin/proven-spf',
  '/account/shop/skin/proven-night-cream',
  '/account/shop/eye',
  '/account/congratulations/eye',
  '/account/congratulations/serum',
  '/account/shop/eye/day-eye-cream',
  '/account/shop/eye/night-eye-cream',
  '/account/shop/serum'
]
export const pdpUrls = [...pdpUrlsMain, ...pdpUrlsRud]
export const cmsUrl = 'cms'

export const shopifyLoginUrl = provenPayUrl + 'shopify-login'

export const CDN_ASSETS = `https://media.${baseCdnHost}/img/`

export const CDN_IMG = `${baseProtocolHost}/cdn-cgi/image/f=auto,onerror=redirect/${CDN_ASSETS}`
export const BUILDER_CDN = `https://cdn.builder.io/api/v1/image/assets%2F`

// Documentation at: https://developers.cloudflare.com/images/url-format
export const getImageUrl = (params = 'f=auto,onerror=redirect', filename) => {
  if (params == null) {
    return filename
  }
  const base_url = `${baseProtocolHost}`
  return `${base_url}/cdn-cgi/image/${params}/${filename}`
}
